import React, {Fragment} from "react";
import {Link, NavLink} from "react-router-dom";

import {ReactComponent as EO4EULogo} from '../../assets/img/eo4eu_logo_final_w.svg';
import md5 from 'md5'
import {Menu, Transition} from "@headlessui/react";
import {useAuth} from "../../components/auth.context";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

const Header:React.FC<{}> = () => {
    return(
        <header className="bg-gray-600 text-white shadow-2xl z-50">
            <div className="container py-2">
                <nav className="flex justify-between items-center">
                    <div className="flex items-center gap-8">
                        <div>
                            <Link className="hover:text-gray-300 transition duration-100" to={'/'} title="Home">
                                <EO4EULogo width={92}/>
                            </Link>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    )
}

export default Header
